var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"index"}},[_c('div',{staticClass:"headerpart"},[_c('img',{staticClass:"pcImg",attrs:{"src":require("../../../../../public/image/ProspectusBAPC.png"),"alt":""}}),_vm._v(" "),_c('img',{staticClass:"isoImg",attrs:{"src":require("../../../../../public/image/ProspectusBAIOS.png"),"alt":""}}),_vm._v(" "),_c('div',{staticClass:"scope"},[_c('div',{staticClass:"cintentBox"},[_c('h1',{staticClass:"content_title"},[_vm._v("\n          "+_vm._s(_vm.$t("nav.Ed"))+"\n        ")])])])]),_vm._v(" "),_c('div',{staticClass:"mainContent",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"above row"},[_c('div',{staticClass:"bigBox col-lg-8 row"},[_c('router-link',{staticClass:"BBox col",attrs:{"to":{
          path: _vm.Earr[2].path,
          query: { id: _vm.Earr[2].meta.id },
        }}},[_c('div',{staticClass:"imgBox col"},[_c('img',{attrs:{"src":_vm.Earr[2].meta.img}})]),_vm._v(" "),_c('div',{staticClass:"contentBox col"},[_c('span',{staticClass:"title"},[_vm._v("\n              "+_vm._s(_vm.Earr[2].meta.name ? _vm.$t(_vm.Earr[2].meta.name) : _vm.Earr[2].name)+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t(_vm.Earr[2].meta.description)))]),_vm._v(" "),_c('span',{staticClass:"learn"},[_c('i',{staticClass:"bi bi-list"}),_vm._v(_vm._s(_vm.$t("nav.LeM"))+"\n            ")])])])],1),_vm._v(" "),_c('div',{staticClass:"col-lg-4 row"},[_c('div',{staticClass:"littleBox  col-xl-12 col-lg-12 col-md-6"},[_c('router-link',{staticClass:"BBox",attrs:{"to":{
            path: ((_vm.Earr[4].name) + "?id=" + (_vm.Earr[4].meta.id) + "&keyword=" + (_vm.Earr[4].meta.keyword)),
          }}},[_c('div',{staticClass:"imgBox col"},[_c('img',{attrs:{"src":_vm.Earr[4].meta.img}})]),_vm._v(" "),_c('div',{staticClass:"contentBox col"},[_c('span',{staticClass:"title"},[_vm._v("\n                "+_vm._s(_vm.Earr[4].meta.name ? _vm.$t(_vm.Earr[4].meta.name) : _vm.Earr[4].name)+"\n              ")]),_vm._v(" "),_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t(_vm.Earr[4].meta.description)))]),_vm._v(" "),_c('span',{staticClass:"learn"},[_c('i',{staticClass:"bi bi-list"}),_vm._v(_vm._s(_vm.$t("nav.LeM"))+"\n              ")])])])],1),_vm._v(" "),_c('div',{staticClass:"littleBox col-xl-12 col-lg-12 col-md-6"},[_c('router-link',{staticClass:"BBox",attrs:{"to":{
            path: _vm.Earr[3].path,
            query: { id: _vm.Earr[3].meta.id },
          }}},[_c('div',{staticClass:"imgBox col"},[_c('img',{attrs:{"src":_vm.Earr[3].meta.img}})]),_vm._v(" "),_c('div',{staticClass:"contentBox col"},[_c('span',{staticClass:"title"},[_vm._v("\n                "+_vm._s(_vm.Earr[3].meta.name ? _vm.$t(_vm.Earr[3].meta.name) : _vm.Earr[3].name)+"\n              ")]),_vm._v(" "),_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t(_vm.Earr[3].meta.description)))]),_vm._v(" "),_c('span',{staticClass:"learn"},[_c('i',{staticClass:"bi bi-list"}),_vm._v(_vm._s(_vm.$t("nav.LeM"))+"\n              ")])])])],1)])]),_vm._v(" "),_c('ul',{staticClass:"row below"},_vm._l((_vm.Earr),function(rou,index){return (index > 4)?_c('li',{key:index,staticClass:"col-lg-4 col-md-6 littleBox"},[_c('router-link',{staticClass:"BBox",attrs:{"to":{
          path: rou.path,
          query: { id: rou.meta.id },
        }}},[_c('div',{staticClass:"imgBox col"},[_c('img',{attrs:{"src":rou.meta.img}})]),_vm._v(" "),_c('div',{staticClass:"contentBox col"},[_c('span',{staticClass:"title"},[_vm._v("\n              "+_vm._s(rou.meta.name ? _vm.$t(rou.meta.name) : rou.name)+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"description"},[_vm._v(_vm._s(rou.meta.description))]),_vm._v(" "),_c('span',{staticClass:"learn"},[_c('i',{staticClass:"bi bi-list"}),_vm._v(_vm._s(_vm.$t("nav.LeM"))+"\n            ")])])])],1):_vm._e()}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }